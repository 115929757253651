<template>
    <div id="home">
      
        <section class="container">
            <div class="row">
                <div class="col-12">
                    xxx<br />
                    <!--GmapMap
                        :center="{lat:form.lat, lng:form.lng}"
                        :zoom="14"
                        map-type-id="terrain"
                        :options="options">
                        <GmapMarker
                        :position="{lat:form.lat, lng:form.lng}"
                        :clickable="true"
                        :draggable="true"
                        @click="setPlace($event)"
                        @drag="updateCoordinates"
                        />
                    </GmapMap-->

                    <GmapMap
                        :center="centerMarker"
                        :zoom="zoom"
                        map-type-id="terrain"
                        style="width: 500px; height: 300px"
                        >
                        <GmapMarker
                            :key="index"
                            v-for="(m, index) in markers"
                            :position="m.position"
                            :clickable="true"
                            :draggable="true"
                            @click="center=m.position"
                        />
                    </GmapMap>
                </div>
            </div>
        </section>
    
    </div>
</template>

<script type="text/javascript">
	import VueGoogleAutocomplete from 'vue-google-autocomplete';
export default {
  components: { VueGoogleAutocomplete },

  data() {
    return {
      address: "",
      currentPlace:null,
    
      centerMarker: { lat:21.0626417, lng:-101.674567 },
      //markers: [
      //  { position: { lat:20.6560349, lng:-103.3111757 } }
      //],
      markers: [],
      zoom: 7,
      

      options: {
        mapTypeControl: false,
      },
      mapLoaded:false,
      directionsService:null,
      form:{
        lat: 21.5063799,
        lng: -104.894684,
        address:null,
      },
      order:{
        id:null
      },
      modal:{
        msg:'',
        icon:'',
        block:false,
      },
    }
  },

  methods: { 
    getPosition(){
      var self = this;
      if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(function(position) {

            self.form.lat = position.coords.latitude;
            self.form.lng = position.coords.longitude;

            //alert('lat'+position.coords.latitude + ' lng'+position.coords.longitude);
            self.centerMarker = { lat: position.coords.latitude, lng: position.coords.longitude };
            self.markers.push({ position: { lat: position.coords.latitude, lng: position.coords.longitude } });
            self.zoom = 14;
            alert(this.centerMarker)

            self.calculateShip();
              
              var geocoder = new google.maps.Geocoder();
              geocoder.geocode({
                  latLng: location.latLng
              }, function(responses) {
                  if (responses && responses.length > 0) {
                    self.form.address = responses[0].formatted_address;
                    self.currentPlace = responses[0].formatted_address;
                  } 
            });

          }, function(error) {
            switch(error.code) {
              case error.PERMISSION_DENIED:
                console.log('El usuario denegó la solicitud de geolocalización.');
                break;
              case error.POSITION_UNAVAILABLE:
                console.log('Información de ubicación no disponible.');
                break;
              case error.TIMEOUT:
                console.log('Tiempo de espera agotado al intentar obtener la ubicación.');
                break;
              case error.UNKNOWN_ERROR:
                console.log('Ocurrió un error desconocido.');
                break;
            }
          });
        } else {
          console.log('Geolocalización no es soportada por este navegador.');
        }
    },
  },

  mounted(){
      var self = this;
      setTimeout(function(){
        self.getPosition();
      }, 2000);
  }
}
</script>
